:root {
  --outer-width: 88vw;
  --outer-height: 88vh;
  --outer-padding-horizontal: 5vw;
  --outer-padding-vertical: 5vh;
  --button-padding-vertical: 10px;
}

* {
  font-family: "Verdana", cursive, sans-serif;
  color: #ffffff;
  line-height: 1.5;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.app {
  background: url("./background.jpg") no-repeat 0 0;
  border-radius: 15px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  padding: var(--outer-padding-vertical) var(--outer-padding-horizontal);
  height: var(--outer-height);
  width: var(--outer-width);
}

.score-text {
  text-align: center;
}

.question-count {
  margin-bottom: 20px;
  text-align: right;
}

.top-section {
  text-align: center;
  margin: 0 calc(-1 * var(--outer-padding-horizontal));
  background: rgba(0, 0, 0, 0.9);
  padding: var(--outer-padding-vertical) var(--outer-padding-horizontal);
}

.bottom-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: auto;
}

button {
  width: calc(var(--outer-width) / 2 - 1vh);
  font-size: 20px;
  color: #ffffff;
  border-radius: 15px;
  padding-block-start: 5vh;
  padding-block-end: 5vh;
  background: rgba(0, 0, 0, 0.55);
  border: none;
  cursor: pointer;
  margin-top: 2vh;
}

button.answer {
  display: flex;
  padding: 0;
  min-height: 140px;
  align-items: center;
  position: relative;
}

button.answer span.count {
  padding-left: 15px;
  width: 30px;
  z-index: 0;
}

button.answer span.count::before {
  content: "";
  background-color: black;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50px;
  z-index: -1;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

button.answer span.count.clicked::before {
  background-color: transparent;
}

button.answer span.text {
  padding: var(--button-padding-vertical) 0;
  margin-left: 20px;
  text-align: left;
}

button.answer svg.icon {
  padding: var(--button-padding-vertical) 0;
  margin-left: auto;
  margin-right: 20px;
  padding-left: 20px;
  height: 28px;
  fill: white;
}

button.full {
  width: 100%;
}

.correct {
  background-color: #2f922f;
}

.incorrect {
  background-color: #ff3333;
}

button:focus {
  outline: none;
}
